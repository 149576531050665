import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import { FleetGenericFilter } from '../../Elements'
import { TableListComp, PageLoader, ExportManu, EmptyCollection, FilterComponent } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Switcher, FleetLease, LeaseTypeGraph, LeaseStatusGraph, LesseeGraph, OwnerGraph } from '../components';
import { getLesseeListAc, getPlatformConstantsLeaseStatusAc } from '../../../shared_elements/actionCreators';
import { getAssetIdentifierApi, getFleetContractsApi, exportFleetLeasesApi, getLeaseTypeGraphApi, getLeaseStatusGraphApi, getOwnerGraphApi, getLesseeGraphApi, getLesseeFilterApi } from '../apiServices';
import { contactFleetHd, leasesFilterOps, leaseTypes } from '../';
import { checkPermission, getLocalStorageInfo } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
import { leaseStatus } from '../../../shared/components';
class FleetLeases extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:false,
      skeletonLoaderLeaseStatus:false,
      skeletonLoaderLeaseType:false,
      skeletonLoaderOwner:false,
      skeletonLoaderLessee:false,
      open:false,
      leases:{ list:[], pagination:{} },
      sort: '',
      sort_by: '',
      filter:{}, // List Filter
      genericFilter:{}, // Analytic Generic Filter
      filterLeaseType:{}, // Analytic Generic Filter 
      filterLeaseStatus:{}, // Analytic Generic Filter 
      filterOwnerGraph:{}, // Analytic Generic Filter 
      filterLesseeGraph:{}, // Analytic Generic Filter 
      mode:'list',
      leaseTypeGraph:{},
      leaseStatGraph:{},
      ownerGraphData:{},
      lesseeGraphData:{},
      assetTypeOptions:[],
      assetModelOptions:[],
      assetsOptions:[],
      lesseeOptions:[],
      lessorOptions:[],
      ownerOptions:[],
      graphHeading:'',
      lesseeFilter:[]
    }
    this.getFleetContractsApi = getFleetContractsApi.bind(this);
    this.exportFleetLeasesApi = exportFleetLeasesApi.bind(this);
    this.getLeaseTypeGraphApi = getLeaseTypeGraphApi.bind(this);
    this.getLeaseStatusGraphApi = getLeaseStatusGraphApi.bind(this);
    this.getOwnerGraphApi = getOwnerGraphApi.bind(this);
    this.getLesseeGraphApi = getLesseeGraphApi.bind(this);
    this.getLesseeFilterApi = getLesseeFilterApi.bind(this);
    this.getAssetIdentifierApi = getAssetIdentifierApi.bind(this);
  }
  componentDidMount(){
    const { genericFilter, filterLeaseType, filterLeaseStatus, filterOwnerGraph, filterLesseeGraph } = this.state;
    this.getFleetContractsApi(this.props, {per_page:50}, 'skeletonLoader');
    this.props.getPlatformConstantsLeaseStatus();
    this.props.getLesseeList();
    this.getLesseeFilterApi();
    this.getLeaseTypeGraphApi(this.props, genericFilter, filterLeaseType, 'skeletonLoaderLeaseStatus');
    this.getLeaseStatusGraphApi(this.props, genericFilter, filterLeaseStatus, 'skeletonLoaderLeaseType');
    this.getOwnerGraphApi(this.props, genericFilter, filterOwnerGraph, 'skeletonLoaderOwner');
    this.getLesseeGraphApi(this.props, genericFilter, filterLesseeGraph, 'skeletonLoaderLessee');
    this.getAssetIdentifierApi('lessor', 'lessorOptions');
    this.getAssetIdentifierApi('lessee', 'lesseeOptions');
    this.getAssetIdentifierApi('owner', 'ownerOptions');
    this.getAssetIdentifierApi('asset_type', 'assetTypeOptions');
    this.getAssetIdentifierApi('asset_model', 'assetModelOptions');
    this.getAssetIdentifierApi('serial_number', 'assetsOptions');
    trackActivity('Page Visited',{
      application_module: 'Lease Management',
      page_title: 'Fleet Lease List'
    })
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getFleetContractsApi(this.props, { ...filter, sort: sortField, sort_by: 'desc', per_page:50 }, 'pageLoader');
      } else {
        this.getFleetContractsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
      }
    } else {
      this.getFleetContractsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
    }
  }
  changeGenericFilter = (e, keyParam, value) => {
    let query = Object.assign({},this.state.genericFilter);
    if(value){
      query = {
        ...query,
        [keyParam]:value.id
      }
    }else{
      delete query[keyParam];
    }
    this.setState({genericFilter: query});
    this.applyGenericFilter(query);
  }
  applyGenericFilter = (genericFilter) => {
    const { filterLeaseType, filterLeaseStatus, filterOwnerGraph, filterLesseeGraph } = this.state;
    this.getLeaseTypeGraphApi(this.props, genericFilter, filterLeaseType, 'skeletonLoaderLeaseType');
    this.getLeaseStatusGraphApi(this.props, genericFilter, filterLeaseStatus, 'skeletonLoaderLeaseStatus');
    this.getOwnerGraphApi(this.props, genericFilter, filterOwnerGraph, 'skeletonLoaderOwner');
    this.getLesseeGraphApi(this.props, genericFilter, filterLesseeGraph, 'skeletonLoaderLessee');
  }
  onClickDataPointFn = (data, category, type) => {
    const { filterLeaseType, filterLeaseStatus, filterLesseeGraph, filterOwnerGraph, genericFilter, sort, sort_by } = this.state;
    const { pltConstantsLeaseStatus } = this.props;
    if (type == 'basedOnType') {
      this.setState({graphHeading:'Based on Type'})
      this.getFleetContractsApi(this.props, { ...filterLeaseType, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, lease_type:leaseTypes.find(item => item.label === data).value}, 'pageLoader', 'underlyingData');
    }
    if (type == 'basedOnStatus') {
      this.setState({graphHeading:'Based on Status'})
      this.getFleetContractsApi(this.props, { ...filterLeaseStatus, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, lease_status:pltConstantsLeaseStatus.find(item => item.label === data).value}, 'pageLoader', 'underlyingData');
    }
    if (type == 'basedOnOwner') {
      this.setState({graphHeading:'Based on Owner'})
      this.getFleetContractsApi(this.props, { ...filterOwnerGraph, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, lease_status:pltConstantsLeaseStatus.find(item => item.label === data).value, owner_id:category.id }, 'pageLoader', 'underlyingData');
    }
    if (type == 'basedOnLessee') {
      this.setState({graphHeading:'Based on Lessee'})
      this.getFleetContractsApi(this.props, { ...filterLesseeGraph, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, lease_status:pltConstantsLeaseStatus.find(item => item.label === data).value, lessee_id:category.id}, 'pageLoader', 'underlyingData');
    }
  }
  render(){
    const { pageLoader, skeletonLoader, skeletonLoaderOwner, skeletonLoaderLessee, skeletonLoaderLeaseType, skeletonLoaderLeaseStatus, filter, filterLeaseType, filterLeaseStatus, filterOwnerGraph, filterLesseeGraph, leases, sort, sort_by, mode, leaseTypeGraph, leaseStatGraph, ownerGraphData, lesseeGraphData, assetTypeOptions, assetModelOptions, assetsOptions, lessorOptions, ownerOptions, open, genericFilter, graphHeading,lesseeOptions, lesseeFilter } = this.state;
    const { lessee, pltConstantsLeaseStatus } = this.props;
    let filterOptions = leasesFilterOps;
    filterOptions = {
      ...filterOptions,
      lessee_id: {
        ...filterOptions.lessee_id,
        options: lesseeOptions
      },
      lease_status: {
        ...filterOptions.lease_status,
        options: getLocalStorageInfo().defaultLessor.id === 348 ? leaseStatus.airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? leaseStatus.jacLeaseStatus :leaseStatus.defaultLeaseStatus 
      },
      lessor_id: {
        ...filterOptions.lessor_id,
        options: lessorOptions
      },
      owner_id: {
        ...filterOptions.owner_id,
        options: ownerOptions
      },
      asset_type:{
        ...filterOptions.asset_type,
        options: assetTypeOptions
      },
      asset_model:{
        ...filterOptions.asset_model,
        options: assetModelOptions
      },
      serial_number:{
        ...filterOptions.serial_number,
        options: assetsOptions
      }
    }
    return(
      <section className="fleet-lease-management-sec">
        <DeploymentMessage/>
        { mode == 'list' ?
          <Fragment>
            { skeletonLoader ? <STableLoader count={7} />:
              <Fragment>
                <Paper square style={{padding:'6px 10px'}}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item md={9}>
                      <FilterComponent 
                          filter={filter}
                          filterMenu={filterOptions}
                          getResponseBack={(applyFilter) => {this.getFleetContractsApi(this.props, {...applyFilter, page:1, per_page:leases.pagination.per_page}, 'pageLoader');}}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <div>
                        <ul className="list-inline" style={{float:'right', display:'flex'}}>
                          {leases.list.length > 0 && checkPermission('contracts','lease_details','EXP') &&
                            <li className="list-inline-item">
                              <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetLeasesApi(this.props, {...filter, download:file.extension})} />
                            </li>
                          }
                          { checkPermission('contracts','lease_details','ANA') ?
                            <li className="list-inline-item">
                              <Switcher mode={mode} onChangeMode={(mode) => this.setState({mode:mode})} />
                            </li>:null
                          }
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
                <TableListComp
                  heads={contactFleetHd}
                  sort={sort}
                  sort_by={sort_by}
                  data={leases.list.map((item,index) =>
                    <FleetLease
                      index={index}
                      item={item} />
                    )
                  }
                  createSortHandler={this.createSortHandler}
                  pagination={leases.pagination}
                  onChangePage={(event, newPage) => this.getFleetContractsApi(this.props, {...filter, page:newPage+1, per_page:leases.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader')}
                  onChangeRowsPerPage={(event) => this.getFleetContractsApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader')}
                  noRecord={ leases.list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              </Fragment>
            }
          </Fragment> :
          <Fragment>
            <section className="fleet-lease-analytics-sec">
              <Paper className="generic-filter-block">
                <Grid container spacing={1}>
                  <Grid item xs={11} md={11}>
                      <FleetGenericFilter 
                        genericFilter={genericFilter}
                        lesseeOptions={lessee}
                        lessorOptions={lessorOptions}
                        assetTypeOptions={assetTypeOptions}
                        assetModelOptions={assetModelOptions}
                        assetsOptions={assetsOptions}
                        ownerOptions={ownerOptions}
                        changeGenericFilter={this.changeGenericFilter}
                        processingLoader={skeletonLoaderLeaseStatus || skeletonLoaderLeaseType || skeletonLoaderOwner || skeletonLoaderLessee}
                      />
                  </Grid>
                  <Grid item md={1}>
                    <ul className='list-inline' style={{float:'right'}}>
                      <li className='list-inline-item'>
                        <Switcher mode={mode} onChangeMode={(mode) => this.setState({mode:mode})} />
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <LeaseTypeGraph 
                      skeletonLoader={ skeletonLoaderLeaseType }
                      leaseTypeGraph={leaseTypeGraph} 
                      filter={filterLeaseType} 
                      callExternalFunction={this.onClickDataPointFn} 
                      getResponseBack={(applyFilter) => this.getLeaseTypeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderLeaseType')}
                      leases={leases}
                      pltConstantsLeaseStatus={pltConstantsLeaseStatus}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <LeaseStatusGraph 
                      skeletonLoader={skeletonLoaderLeaseStatus}
                      leaseStatGraph={leaseStatGraph} 
                      filter={filterLeaseStatus} 
                      callExternalFunction={this.onClickDataPointFn}
                      getResponseBack={(applyFilter)=>this.getLeaseStatusGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderLeaseStatus')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OwnerGraph 
                      skeletonLoader={skeletonLoaderOwner}
                      ownerOptions={ownerOptions}
                      ownerGraphData={ownerGraphData} 
                      filter={filterOwnerGraph} 
                      callExternalFunction={this.onClickDataPointFn} 
                      ownerOption={this.state.ownerOption}
                      getResponseBack={(applyFilter) => this.getOwnerGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderOwner')}
                      pltConstantsLeaseStatus={pltConstantsLeaseStatus}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LesseeGraph 
                      lesseeOptions={lessee}
                      skeletonLoader={skeletonLoaderLessee}
                      lesseeGraphData={lesseeGraphData} 
                      filter={filterLesseeGraph} 
                      callExternalFunction={this.onClickDataPointFn} 
                      lesseeOption={this.state.lesseeOption}
                      getResponseBack={(applyFilter) => this.getLesseeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderLessee')}
                      pltConstantsLeaseStatus={pltConstantsLeaseStatus}
                    />
                </Grid>
              </Grid>
            </section>
          </Fragment>
        }
        { open ? 
          <Dialog
            open={open}
            anchor="right"
            onClose={() => {this.setState({open:false});this.getFleetContractsApi(this.props, {per_page:50}, 'pageLoader');}}
            fullScreen
          >
            <DialogTitle id="form-dialog-title">
            {graphHeading ? graphHeading :'Underlying Data' }
              <ul className='list-inline' style={{float:'right'}}>
                <li className='list-inline-item'>
                  <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetLeasesApi(this.props, {...filter, download:file.extension})} />
                </li>
              </ul>
            </DialogTitle>
            <DialogContent dividers={true}>
                <TableListComp
                    heads={contactFleetHd}
                    data={leases.list.map((item,index) =>
                    <FleetLease
                        index={index}
                        item={item} />
                    )
                    }
                    pagination={leases.pagination}
                    onChangePage={(event, newPage) => this.getFleetContractsApi(this.props, {...filter, page:newPage+1, per_page:leases.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    onChangeRowsPerPage={(event) => this.getFleetContractsApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    noRecord={ leases.list.length ? null:
                      <EmptyCollection
                          title="No records found"
                      />
                    }
                />
            </DialogContent>
            <DialogActions style={{paddingLeft:'24px',paddingRight:'24px'}}>
                <Button onClick={() => {this.setState({open:false});this.getFleetContractsApi(this.props, {per_page:50}, 'pageLoader');}} color='primary' variant='outlined' size='small'>Close</Button>
            </DialogActions>
          </Dialog>:null    
        }
        { pageLoader ? <PageLoader/>:null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  lessee: state.shareReducer.lessee,
  pltConstantsLeaseStatus: state.shareReducer.pltConstantsLeaseStatus
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLesseeList: () => dispatch(getLesseeListAc({application:3})),
    getPlatformConstantsLeaseStatus: () => dispatch(getPlatformConstantsLeaseStatusAc({constant_types:['lease_status']}))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(FleetLeases))
