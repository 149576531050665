import moment from 'moment';
import { browserHistory } from 'react-router';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../utils_v2';
import { trackActivity } from '../../utils_v2/mixpanel';
import { fieldValidation, dateRangeValidation } from '../../utils_v2/formValidation';
import { errorCode } from './';

export function getInvoiceInitiateApi(props, query){
  globalGetService(`contracts/${props.params.contract_slug}/invoices/initialize/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      let payout = Object.assign({}, response.data.data);
      if(payout.bank_details.length){
        this.setState({bankList:payout.bank_details});
        let dates = [];
        if(query.invoice_type === 1 || query.invoice_type === 2){
          dates = payout.months.map(month => { return {label:moment(month).format('MMM-YYYY'),value:month}})
          this.setState({dates})
        }
        if(query.invoice_type === 6){
          this.setState({securityDeposits:payout.security_deposit})
        }
        if(query.invoice_type === 7){
          this.setState({insurancesOptions: payout.insurance.map(item => {return{id:item.insurance_id, type:`${item.type}(${item.insurance_number})` }})})
        }
        if(query.invoice_type === 8){
          this.setState({claimOptions: payout.insurance.map(item => {return{id:item.insurance_id, type:`${item.type}(${item.insurance_number})` }})})
        }
        if(query.invoice_type === 9){
          let dates = [];
          dates = payout.map(item => {return {label:`${item.invoice_number} ( ${moment(item.invoice_date).format('MMM, YYYY')} )`,value:item.id}})
          this.setState({dates})
        }
        if([3,4,5].includes(query.invoice_type)){
          let dates = [];
          dates = payout.map(item => { return {label:`${item.invoice_number}`,value:item.id}})
        }
      }else{
        this.props.enqueueSnackbar('No Banking Detail available or linked. To generate invoice add/link atleast one banking detail.', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    }
  })
}
export function getDefaultBankName(props){
  globalGetService(`contracts/${props.params.contract_slug}/invoices/initialize/`,{invoice_type:'2'})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({bankList:response.data.data});
    }
  })
}
export function  getAutoInvoiceOn (){
  globalGetService(`console/auto-invoice-generate/`)
  .then(response => {
     this.setState({autoInvoiceOn:response.data.data});
    })
    .catch(error => {
      console.log(error);
    });
}

export function genInvoiceStrApi(props, data){
  this.setState({pageLoader:true});
  globalPostService(`contracts/${props.params.contract_slug}/invoices/structure/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      browserHistory.push(`/${props.params.type}/${props.params.aircraft_slug}/contract/${props.params.contract_slug}/invoice/view/${response.data.data.id}`);
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getInvoiceWidgetApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/invoices/widget/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({invoiceWidget:response.data.data});
    }
  })
}

export function getFleetInvoicesApi(props, query={}, loaderType, dataType){
  this.setState({[loaderType]:true});
  globalGetService(`contracts/v2/list-invoice/`, query)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      delete query.page;
      delete query.per_page;
      delete query.sort;
      delete query.sort_by;
      this.setState({
        invoices:response.data.data,
        filter:query
      });
      if(dataType){
        this.setState({open:true})
      }
    }
  })
}
export function getInvoicesListApi(props, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = Object.assign({}, query);
  delete filterQuery.due_date
  delete filterQuery.transactionDate
  if(query.due_date && query.due_date.due_start_date){
    filterQuery = {
      ...filterQuery,
      due_start_date:query.due_date.due_start_date
    }
  }
  if(query.due_date && query.due_date.due_end_date){
    filterQuery = {
      ...filterQuery,
      due_end_date:query.due_date.due_end_date
    }
  }
  if(query.transactionDate && query.transactionDate.startDate){
    filterQuery = {
      ...filterQuery,
      startDate:query.transactionDate.startDate
    }
  }
  if(query.transactionDate && query.transactionDate.endDate){
    filterQuery = {
      ...filterQuery,
      endDate:query.transactionDate.endDate
    }
  }
  globalGetService(`contracts/${props.params.contract_slug}/invoice/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({invoices:response.data.data, filter:query})
    }
  })
}
export function exportInvoicesListApi(props, query, loaderType){
  this.setState({pageLoader:true});
  let filterQuery = Object.assign({}, query);
  delete filterQuery.dueDate
  delete filterQuery.transactionDate
  if(query.dueDate && query.dueDate.dueStartDate){
    filterQuery = {
      ...filterQuery,
      dueStartDate:query.dueDate.dueStartDate
    }
  }
  if(query.dueDate && query.dueDate.dueEndDate){
    filterQuery = {
      ...filterQuery,
      dueEndDate:query.dueDate.dueEndDate
    }
  }
  if(query.transactionDate && query.transactionDate.startDate){
    filterQuery = {
      ...filterQuery,
      startDate:query.transactionDate.startDate
    }
  }
  if(query.transactionDate && query.transactionDate.endDate){
    filterQuery = {
      ...filterQuery,
      endDate:query.transactionDate.endDate
    }
  }
  globalExportService(`contracts/${props.params.contract_slug}/invoice/`)
  .then(response => {
    this.setState({pageLoader:false});

  })
}

export function getInvoiceApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/invoice/${this.props.params.id}/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        invoiceInfo:response.data.data
      })
    }
  })
}
export function toggleInvoiceStatusApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      globalPostService(`contracts/${props.params.contract_slug}/invoices/${props.params.id}/status/`, data)
      .then(response => {
        resolve(response);
      })
    }
  )
}
export function getSharedInvoiceInfoApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/invoices/${props.params.id}/initiate-mail/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      let payload = Object.assign({}, response.data.data);
      payload = {
        ...payload,
        to_address:response.data.data.to_address && response.data.data.to_address.length ? response.data.data.to_address.map(email => {return{label:email,inputValue:email}})  :[]
      }
      this.setState({sharedInfo:payload, modal:true});
    }
  })
}

export function sendInvoiceMailApi(props, data){
  let payload = Object.assign({}, data);
  payload = {
    ...payload,
    to_address:data.to_address.map(item => item.inputValue)
  }
  this.setState({pageLoader:true});
  globalPostService(`contracts/${props.params.contract_slug}/invoices/${props.params.id}/send-mail/`, payload)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        modal:false,
        sharedInfo:{to_address:[], subject:'',content:''},
        error:{}
      });
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function exportInvoiceApi(props={}, invoice_number, query){
  this.setState({pageLoader:true});
  globalExportService(`contracts/${props.params.contract_slug}/invoice/${props.params.id}/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    downloadFileType(response.data, (`${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}_${invoice_number}`), query.download);
    this.props.enqueueSnackbar('Invoice Exported Successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function editInvoiceApi(props={}, data={}){
  let payload = Object.assign({}, data);
  let invoiceItemsFlag = true;
  let invoiceItemsError = [];
  let validationInputs = {
    invoice_number:errorCode['invoice_number'][fieldValidation({...errorCode['invoice_numberObj'], fieldval: payload.invoice_number})],
    date:errorCode['date'][fieldValidation({...errorCode['dateObj'], fieldval: payload.date})],
    due_date:errorCode['due_date'][fieldValidation({...errorCode['due_dateObj'], fieldval: payload.due_date})],
    start_date:errorCode['start_date'][fieldValidation({...errorCode['start_dateObj'], fieldval: payload.start_date})],
    end_date:errorCode['end_date'][fieldValidation({...errorCode['end_dateObj'], fieldval: payload.end_date})],
    libor_date:payload.invoice_type === 3 ? errorCode['libor_date'][fieldValidation({...errorCode['libor_dateObj'], fieldval: payload.libor_date})]:'',
    to_company_name:errorCode['to_company_name'][fieldValidation({...errorCode['to_company_nameObj'], fieldval: payload.to_company_name})],
    from_company_name:errorCode['from_company_name'][fieldValidation({...errorCode['from_company_nameObj'], fieldval: payload.from_company_name})],
    libor_value: payload.invoice_type === 3 ? errorCode['libor_value'][fieldValidation({...errorCode['libor_valueObj'], fieldval: payload.libor_value})]:'',
    additional_rate:payload.invoice_type === 3 ? errorCode['additional_rate'][fieldValidation({...errorCode['additional_rateObj'], fieldval: payload.additional_rate})]:'',
  };
  if(payload.start_date && payload.end_date){
    this.props.enqueueSnackbar('Data fields are not Correct, please check', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    validationInputs = {
      ...validationInputs,
      start_date: dateRangeValidation(payload.start_date, payload.end_date, 1)
    }
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    this.setState({pageLoader:true});
    globalPutService(`contracts/${props.params.contract_slug}/invoice/${props.params.id}/`, payload)
    .then(response => {
      this.setState({pageLoader:false})
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        browserHistory.push(`/${props.params.type}/${props.params.aircraft_slug}/contract/${props.params.contract_slug}/invoice/view/${props.params.id}`);
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState({error:validationInputs});
  }
}

export function deleteInvoiceApi(props={}){
  globalDeleteService(`contracts/${props.params.contract_slug}/invoice/${props.params.id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({deleteModal:false});
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      browserHistory.push(`/${props.params.type}/${props.params.aircraft_slug}/contract/invoice/`);
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function exportFleetInvoicesApi(props, query){
  this.setState({pageLoader:true});
  let filterQuery = Object.assign({}, query);
  delete filterQuery.due_date;
  delete filterQuery.invoice_period;
//************* due_date range code starts **********
  if(query.due_date && query.due_date.due_start_date){
    filterQuery = {
      ...filterQuery,
      due_start_date:query.due_date.due_start_date
    }
  }
  if(query.due_date && query.due_date.due_end_date){
    filterQuery = {
      ...filterQuery,
      due_end_date:query.due_date.due_end_date
    }
  }
  //************* due_date range code end **********

  //******* invoice_period range code starts *******
  if(query.invoice_period && query.invoice_period.invoice_start_date){
    filterQuery = {
      ...filterQuery,
      invoice_start_date:query.invoice_period.invoice_start_date
    }
  }
  if(query.invoice_period && query.invoice_period.invoice_end_date){
    filterQuery = {
      ...filterQuery,
      invoice_end_date:query.invoice_period.invoice_end_date
    }
  }
  //******* invoice_period range code ends *******
  globalExportService(`contracts/v2/list-invoice/`, filterQuery)
  .then(response => {
    this.setState({pageLoader:false});
    trackActivity('Report Exported', {
      page_title: 'Fleet Invoices List',
      application_module: 'Lease Management',
      activity_type:'Report sent to email',
      file_type: filterQuery.download
    })
    this.props.enqueueSnackbar("Request Queued!! You'll receive the requested file on your registered email shortly.", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function getInvoiceTypeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/invoice-summary/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        invoiceTypeGraph: response.data.data,
        filterInvoiceType: query
      })
    }
  })
}

export function geInvoiceStatusGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/invoice-type-summary/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        invoiceStatGraph: response.data.data,
        filterInvoiceStatus: query
      })
    }
  })
}

export function getLesseeTypeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/invoice-type-lessee/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        invoiceLesseeTypeGraphData: response.data.data,
        filterLesseeType: query
      })
    }
  })
}

export function getInvoiceLesseeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/invoice-status-lessee/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        invoicelesseeGraphData: response.data.data,
        filterInvLessee: query
      })
    }
  })
}

export function getAssetIdentifierApi(assetIdentifier, stateType, query={}){
  globalGetService(`contracts/v2/dashboard/lease-generic-filters/${assetIdentifier}/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({[stateType]:response.data.data})
    }
  })
}
export function bulkInvoiceUpdateApi(bulkIds, props){
  this.setState({pageLoader:true})
  globalPutService(`contracts/bulk-invoice-status/`, {ids:bulkIds})
  .then(response=>{
    if(checkApiStatus(response)){
      this.setState({bulkIds:[]})
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.getFleetInvoicesApi(props,{per_page:50}, 'pageLoader')
    }
    else{
      this.props.enqueueSnackbar(response.data?.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({pageLoader:false})
    }
  })
}










